import React from 'react';
import './title.css';

const Title = () => {
  return (
      <h4 className='main-title'>Guerreros Roller Toluca Sauces</h4>     
  );
}

export { Title };
