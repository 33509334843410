import React from 'react';
import './App.css';
import { Title } from './components/title/title';
import { Paragraph } from './components/paragraph/paragraph';
import { ButtonLink } from './components/buttonLink/buttonLink';

const App = () => {
  return (
    <div className="App">
      <nav className="navbar navbar-light">
        <div className="container-fluid">
          <span className="navbar-brand mb-0 h1">Escuela de patinaje</span>
          <img width="50" height="50" className='roller-img' src="https://img.icons8.com/ios-filled/50/roller-skating.png" alt="roller-skating"/>
        </div>
      </nav>
      <div className='container'>
        <Title/>
        <Paragraph/>
        <ButtonLink/>
      </div>
    </div>
  );
}

export default App;
