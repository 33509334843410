import React from "react";
import "./paragraph.css";
import profile from "../../assets/guerreros.jpeg";

const Paragraph = () => {
  return (
    <>
       <div>
        <img className="profile-pic" src={profile} alt="profile-img" />
      </div> 
    </>
  );
};

export { Paragraph };
