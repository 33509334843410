import React from 'react';
import './buttonLink.css';


const ButtonLink = () => {
 
  return (
    <div className='botonera'>
      <a target="_blank" className='btn-custom whatsapp d-flex justify-content-around' rel="noreferrer"
        href='https://api.whatsapp.com/send?phone=+527224616208&text=Me+puede+dar+informes+sobre+las+clases+de+patinaje.'>
          <p className='custom-p'>WhatsApp</p>
          <img className='icon-social' width="100" height="100" src="https://img.icons8.com/stickers/100/whatsapp.png" alt="whatsapp"/>
      </a>
      <a target="_blank" className='btn-custom facebook d-flex justify-content-around' rel="noreferrer"
        href='https://www.facebook.com/share/g4F8ybcLXovDNiHa'>
          <p className='custom-p'>Facebook</p>
        <img className='icon-social' src="https://img.icons8.com/stickers/100/facebook.png" alt="facebook"/>
      </a>
      <a target="_blank" className='btn-custom instagram-button d-flex justify-content-around' rel="noreferrer"
        href='https://www.instagram.com/guerrerosrollerstoluca?igsh=MTBnZXViNWZveDZuZg=='>
        <p className='custom-p'>Instagram</p>
      <img className='icon-social' src="https://img.icons8.com/stickers/100/instagram-new--v1.png" alt="instagram-new--v1"/>
      </a>
      <a target="_blank" className='btn-custom tiktok-button d-flex justify-content-around' rel="noreferrer"
        href='https://www.tiktok.com/@guerrerosrollers?_t=8qonc8Gxvvi&_r=1'>
        <p className='custom-p'>TikTok</p>
      <img className='icon-social tiktok-img' src="https://img.icons8.com/doodle/48/000000/tiktok--v1.png" alt="tiktok--v1"/>
      </a>
      <a target="_blank" className='btn-custom maps d-flex justify-content-around' rel="noreferrer"
        href='https://maps.app.goo.gl/eD6Dq1jhj433roM68'>
        <p className='custom-p'>Maps</p>
      <img className='icon-social' src="https://img.icons8.com/stickers/100/google-maps-new.png" alt="google-maps-new"/>
      </a>
      <a target="_blank" className='btn-custom web d-flex justify-content-around' rel="noreferrer"
        href='https://guerreros-roller-web.solu-tec.net/'>
        <p className='custom-p'>Nuestra Web</p>
      <img className='icon-social' src="https://img.icons8.com/stickers/50/domain.png" alt="google-maps-new"/>
      </a>
      <p className='text-p'>
        Sitio creado por <a target="_blank" href="https://www.linkedin.com/in/pedro-ivan-juarez" rel="noreferrer">Iván Juárez</a> contacto: ivanju21@gmail.com
        </p>
      <a target="_blank" href="https://icons8.com" rel="noreferrer">Iconos de Icons8</a>

      
    </div>
    );
}

export { ButtonLink };
